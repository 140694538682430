body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
  background-color: #f4f4f4;
}
.container {
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
h1 {
  text-align: center;
  color: #9b4dca;
  margin-bottom: 20px;
}
#text-area {
  min-height: 200px;
  border: 1px solid #e1e1e1;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #fafafa;
}
#choices {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
#choices button {
  margin: 5px;
}
#system-message {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 0.9em;
  color: #666;
}
